import request from '../utils/request.js'

/**
 * 获取消息
 * @param {msgType} msgType
 */
export const getMyMsgCenten = (msgType, pageNum, pageSize, hideLoad) =>request({
	method: 'GET',
	url: '/msg/getMyMsgCenten',
	params: {msgType, pageNum, pageSize, hideLoad}
})

/**
 * 一键设置已读
 */
export const setReadState = () =>request({
	method: 'GET',
	url: '/msg/setReadState',
})

/**
 * 不用传token  绑定个推别名
 * @param {*} cid
 */
export const regGeTuiByCid = (cid, hideLoad) => request({
	method: 'GET',
	url: '/msg/regGeTuiByCid',
	params: {cid, hideLoad}
})

/**
 * 绑定用户个推别名
 * @param {*} cid
 */
export const bindAliasByCid = (cid, hideLoad) => request({
	method: 'GET',
	url: '/msg/bindAliasByCid',
	params: {cid, hideLoad}
})

/**
 * 获取用户未读消息数量
 * msgType： 消息类型 1系统通知 2交易通知 3活动通知 4异常消息 5热点推送 0全部
 */
export const getUnreadMsgCount=(msgType, hideLoad) => request({
	method: 'GET',
	url: '/msg/getUnreadMsgCount',
	params: {msgType, hideLoad}
})

/**
 * 读取单条消息内容
 * @param {*} msgId
 */
export const getMsgById = (msgId) => request({
	method: 'GET',
	url: '/msg/getMsgById',
	params: {msgId}
})
